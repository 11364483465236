<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		
		<div v-if="!loadingData">

			<a-row :gutter="24">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24">
					<a-card style="padding-left: 20px; padding-right: 20px; padding-top: 20px;">

						<a-row :gutter="24">

							<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24">
								<h5>Add User Identifications</h5>
							</a-col>

							<a-col :span="24"  :md="12" :lg="12" :xl="12" class="text-right">
								<a-button :loading="loadingDownload" id="btn" type="silabu" @click="downloadCSV" style="margin-right: 20px;">
									<a-icon type="download" theme="outlined" style="font-size: 14px;" /> &nbsp;&nbsp; CSV Template
								</a-button>
								<a-button id="btn" type="silabu" class="" @click="toClassUsers">
									<a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp; Back
								</a-button>
							</a-col>

						</a-row>


						<a-row :gutter="24">

							<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24">
								<a-form-model
									class="users-form"
									@submit.prevent="handleSubmit"
									:hideRequiredMark="true">

									<a-row :gutter="24">

										<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-12">
											<a-form-item class="mb-0" label="Class" :colon="false">
												<a-select 
													placeholder="Select Class"
													v-model="userIdentifications.classUuid" 
													:options="classes" 
													style="border: none !important; margin-top: 0px !important; padding-top: 0px !important;" class="text-right">
													
												</a-select>
											</a-form-item>
										</a-col>
									

										<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-12">
											<a-form-item class="" label="File" :colon="false">
												<a-input 
													type="file"
													ref="file"
													accept=".csv"
													v-decorator="['file',
														{ rules: [{ required: true, message: 'Please upload file!' }] },
													]" placeholder="File" 
													@change="handleFileUpload( $event )"/>
											</a-form-item>
										</a-col>
									</a-row>

									<!-- <a-row class="mt-24">
										<a-col>
											{{ userIdentifications.institutionExternalIds }}
										</a-col>
									</a-row> -->

									<a-form-item class="text-left">
										<a-button type="silabu" :loading="userIdentifications.loading" id="btn" html-type="submit" class="login-form-button text-white">
											Save
										</a-button>
									</a-form-item>

                        		</a-form-model>
							</a-col>
						</a-row>


						
						
					</a-card>
				</a-col>

			</a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin size="large" />

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import Papa from 'papaparse';
	import { notification } from 'ant-design-vue';
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {
				form: this.$form.createForm(this, { name: 'institution_users' }),
				loadingData: false,

				classes: [],
				currentPage: 1,
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				loadingDownload: false,

				file: '',
				content: [],
				parsed: false,

				userIdentifications: {
					institutionUuid: null,
					classUuid: null,
					institutionExternalIds: [],
					loading: false
				}
				
      		}
		},
		created() {
			this.getInstituteClasses()
		},
		methods: {

			async toClassUsers() {
				
				this.$router.push(`/class-users`);
			},

			handleFileUpload(event){
				this.file = event.target.files[0];
				this.parseFile();
			},

			async downloadCSV() {
				this.loadingDownload = true;

				let url = `${this.$BACKEND_URL}/downloads/Identification-Template.csv`;

				window.location.href = url

				this.loadingDownload = false;
			},

			parseFile(){
				this.userIdentifications.institutionExternalIds = []
				Papa.parse( this.file, {
					header: true,
					skipEmptyLines: true,
					complete: function( results ){
						
						if(results.data && results.data[0].Identification != null)  {
							
							results.data.forEach(element => {

								if(element.Identification != null || element.Identification != "") {
									console.log(element)
									
									this.userIdentifications.institutionExternalIds.push({externalId: element.Identification, extraExternalId: element.Extra_Identification})
								}
								
							});
						}else{
							console.log("================>")
							this.notify('Make sure the First Cell(A1) and the Second Cell(B1) are labeled with Identification and Extra_Identification respectively as template provided and try again!!!', 'error')
						}
						
						this.parsed = true;
					}.bind(this)
				} );
			},

			async getInstituteClasses() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")
				let institution_details = JSON.parse(await localStorage.getItem("institution_details"))

                const currentPage = 1;
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/educational-institutions/classes/${institution_details.uuid}/?page=${currentPage}&perPage=${perPage}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						response.data.data.forEach(clazz => {
							this.classes.push({ label: clazz.name, value: clazz.uuid })
						});;
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			handleSubmit() {
				this.form.validateFields(async (err, values) => {
					
					if ( !err ) {

						this.userIdentifications.loading = true;

						let token = await localStorage.getItem("user_token")

						let url = `${this.$BACKEND_URL}/educational-institutions/class-users-csv`;

						const config = {
							headers: {
								'Content-Type': 'application/json',
								'Authorization': `Bearer ${token}`,
							},
						};

						let institution_details = JSON.parse(await localStorage.getItem("institution_details"))

						this.userIdentifications.institutionUuid = institution_details.uuid;

						this.$AXIOS.post(url, this.userIdentifications, config).then((response) => {
							
							this.userIdentifications.classUuid = null
							this.userIdentifications.userIdentifications = []
							this.userIdentifications.loading = false;

							this.notify(response.data.message ?? "Details saved successfully", 'success')

							this.form.resetFields()

							// this.loadingData = false;

						}).catch(async(error) => {

							this.notify(error.response.data.message ?? 'Connection errror', 'error')
							
							this.userIdentifications.loading = false;

							if(error.response && error.response.status == 401) {
								await localStorage.setItem("user_token", null);
								await localStorage.setItem("user_details", null)

								this.$router.push(`/sign-in`);
							}
						});

					}
				})
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 5,
                });
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a{
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:color{
    color: #734EBF !important;
}

#classImg {
	border-radius: 10px;
}

a:hover {
    color: inherit !important;
}
</style>