<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		

		<div v-if="!loadingData">


            <a-row :gutter="24">
                <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-12" style="margin-top: 30px;">
                    <a-row :gutter="24">

                        <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
                            
                            <WidgetCounter
                                title="Active Class Members"
                                :value="classMemberStats.activeMembers"
                                prefix=""
                                suffix=""
                                :icon="userIcon"
                            ></WidgetCounter>
                        </a-col>

                        <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
                            <WidgetCounter
                                title="Inactive Class Members"
                                :value="classMemberStats.inactiveMembers"
                                prefix=""
                                suffix=""
                                :icon="userIcon"
                            ></WidgetCounter>
                        </a-col>

                        <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
                            <!-- <WidgetCounter
                                title="Joined Users"
                                :value="1432"
                                prefix=""
                                suffix=""
                                :icon="userIcon"
                            ></WidgetCounter> -->
                        </a-col>

                        <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
                            <!-- <WidgetCounter
                                title="Course Completion Users"
                                :value="975343"
                                prefix=""
                                suffix=""
                                :icon="userIcon"
                            ></WidgetCounter> -->
                        </a-col>
                        <!-- {{ statistics }} -->
                    </a-row>

                </a-col>
            </a-row>
			

			<a-row :gutter="24">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24">
					<a-tabs v-model="activeTab">
						<a-tab-pane key="Note" tab="Note">
							<a-card style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">

								<a-row :gutter="24">
									<a-col :span="24" :md="12" :lg="12" :xl="24" class="mt-12">
										<h5>List of Watching Users</h5>
									</a-col>
								</a-row>

								<a-row :gutter="24">
									<a-col :span="24" :md="24" :lg="24" :xl="24">
										
										<a-table 
											:columns="noteTableColumns" 
											:data-source="noteStats.playbackUsers" 
											:pagination="true" 
											width="100%" 
											style="width: 100%;">

											<template slot="s_no" slot-scope="s_no, record, index">
												{{ index + 1 }}
											</template>

											<template slot="fullName" slot-scope="row">
												<h6 class="m-0">
													{{ row != null ? `${row.first_name } ${row.last_name}` : '' }} 
												</h6>
											</template>

											<template slot="mobile" slot-scope="row">
												{{ row.mobile != null ? `${row.mobile}` : '-' }}
											</template>

											<template slot="email" slot-scope="row">
												{{ row.email != null ? `${row.email}` : '-' }}
											</template>

											<template slot="duration" slot-scope="row">
												{{ (row.played_duration / row.total_duration) * 100 }}%
											</template>

										</a-table>
									</a-col>
								</a-row>

							</a-card>
						</a-tab-pane>
						<a-tab-pane key="Quiz" tab="Quiz" force-render>
							<a-card style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">

								<a-row :gutter="24">
									<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mt-12">
										<h5>List of Quiz Takers</h5>
									</a-col>
								</a-row>

								<a-row :gutter="24">
									<a-col :span="24" :md="24" :lg="24" :xl="24">
										
										<a-table 
											:columns="quizTableColumns" 
											:data-source="quizStats.results" 
											:pagination="true" width="100%" 
											style="width: 100%;">

											<template slot="s_no" slot-scope="s_no, record, index">
												{{ index + 1 }}
											</template>

											<template slot="fullName" slot-scope="row">
												<h6 class="m-0">
													{{ `${row.firstName } ${row.lastName}` }} 
												</h6>
											</template>

											<template slot="mobile" slot-scope="row">
												{{ row.phoneNumber }}
											</template>

											<template slot="email" slot-scope="row">
												{{ row.email }}
											</template>

											<template slot="score" slot-scope="row">
												{{ row.score }}%
											</template>

											<template slot="grade" slot-scope="row">
												{{ getGradeScore(row.score)}}
											</template>

											<template slot="lastSeen" slot-scope="row">
												{{ moment(row.lastSeen).format('DD MMM YYYY HH:mm A') }}
											</template>

										</a-table>
									</a-col>
								</a-row>

								<!-- <a-row :gutter="24">
									<a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-24 mb-24 text-right" style="margin-top: 20px;">
										<a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
									</a-col>
								</a-row> -->

							</a-card>
						</a-tab-pane>
					</a-tabs>
					
				</a-col>

			</a-row>
			
			

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin size="large" />

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import Papa from 'papaparse';
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	const noteTableColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'Full Name',
			scopedSlots: { customRender: 'fullName' },
			class: 'font-bold',
			width: 250,
		},
		{
			title: 'Phone Number',
			scopedSlots: { customRender: 'mobile' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Email',
			scopedSlots: { customRender: 'email' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Played Duration(%)',
			scopedSlots: { customRender: 'duration' },
			class: 'text-muted text-sm',
		},
		{
			title: 'External ID',
			scopedSlots: { customRender: 'external_id' },
			class: 'text-muted text-sm',
		},
	];

	const quizTableColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'Full Name',
			scopedSlots: { customRender: 'fullName' },
			class: 'font-bold',
			width: 250,
		},
		{
			title: 'Phone Number',
			scopedSlots: { customRender: 'mobile' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Email',
			scopedSlots: { customRender: 'email' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Score(%)',
			scopedSlots: { customRender: 'score' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Grade',
			scopedSlots: { customRender: 'grade' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Last Seen',
			scopedSlots: { customRender: 'lastSeen' },
			class: 'text-muted text-sm',
		},
	];

    const userIcon = `
			<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
				<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
				<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
				<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
			</svg>
		`

	export default ({

		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				classes: [],
				currentPage: 1,
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },
                userIcon,
				activeTab: "Note",

				noteTableColumns,
				quizTableColumns,
				file: '',
				content: [],
				parsed: false,

                classMemberStats: {
                    activeMembers: 0,
                    inactiveMembers: 0
                },

				noteStats: {
					playbackNumAbove80: 0,
					playbackNumLess80: 0,
					playbackNumLess50: 0,
					playbackNumLess20: 0,
					playbackUsers: [],
				},

				quizStats: {
					results: [],
				},

				noteUuid: null,
				quizUuid: null,
				notes: [],
				quizes: [],
				
				
      		}
		},
		watch: {
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getInstituteQuizTakers()
                },
            },
            deep: true,
        },
		created() {
			this.getInstituteClassMemberStats();
			this.getInstituteQuizNoteList();
			this.getInstituteQuizTakers();
		},
		methods: {

			async onViewClass(uuid) {
				
				this.$router.push(`/class-management/view/${uuid}`);
			},

			getGradeScore(marks) {
				let grade = '';
				if(marks >= 70) {
					grade = 'PASS'
				}

				else if(marks >= 50) {
					grade = 'IN PROGRESS'
				}

				else {
					grade = 'FAILED'
				}

				return grade
			},

			handleFileUpload(event){
				this.file = event.target.files[0];
				this.parseFile();
			},

			parseFile(){
				Papa.parse( this.file, {
					header: true,
					skipEmptyLines: true,
					complete: function( results ){
						this.content = results;
						this.parsed = true;
					}.bind(this)
				} );
			},


            async getInstituteClassMemberStats() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")
				let user_details = JSON.parse(await localStorage.getItem("user_details"))
				let institution_details = JSON.parse(await localStorage.getItem("institution_details"))

                const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/educational-institution-reports/class/${institution_details.uuid}/${this.$route.params.uuid}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.classMemberStats.activeMembers = response.data.activeMembers;
						this.classMemberStats.inactiveMembers = response.data.inactiveMembers;
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getInstituteQuizNoteList() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/educational-institution-reports/quiz-notes-list/${this.$route.params.uuid}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                await this.$AXIOS.get(url, config).then((response) => {
                    if (response.status == 200) {
						this.quizes  = response.data.quizes;
						this.notes  = response.data.notes;

						if(response.data.notes.length > 0) {
							this.noteUuid = response.data.notes[0].uuid;
							this.getInstituteNoteStats();
						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getInstituteNoteStats() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/educational-institution-reports/notes/${this.noteUuid}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.noteStats.playbackNumAbove80  = response.data.playbackNumAbove80;
						this.noteStats.playbackNumLess80  = response.data.playbackNumLess80;
						this.noteStats.playbackNumLess50  = response.data.playbackNumLess50;
						this.noteStats.playbackNumLess20  = response.data.playbackNumLess20;
						this.noteStats.playbackUsers  = response.data.playbackUsers
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getInstituteQuizTakers() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/educational-institutions/class-quiz-takers/${this.$route.params.uuid}/?page=${currentPage}&perPage=${perPage}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.quizStats.results  = response.data;
						// this.pagination.totalPages = response.data.totalPages;
						// this.pagination.total = response.data.total;
						// this.pagination.currentPage = response.data.currentPage;
						// this.currentPage = response.data.currentPage;
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a{
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:color{
    color: #734EBF !important;
}

#classImg {
	border-radius: 10px;
}

a:hover {
    color: inherit !important;
}

#viewBtn, #viewBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}
</style>