<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10 pb-1">

		<a-row :gutter="24" class="mt-10">

			<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24">
				<h4>List of Folders</h4>
			</a-col>

			<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-right">
				<h1 v-if="!isCreator"></h1>
				<a-button id="btn" type="silabu" @click="launchModal" v-if="isCreator">
					<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Folder
				</a-button>
			</a-col>

		</a-row>
		
		<div v-if="!loadingData && message.length == 0">

			<a-row :gutter="24">

				<a-col class="mb-24" style="height: 100%;" :span="24" :md="6" :lg="6" :xl="6" v-for="qz in directories" :key="qz.uuid">
					<a-card class=" px-5 h-100">
						<a class="text-dark" style="text-decoration: none !important;" :href="isCreator && `/#/my-classes/view/${classDetails.uuid}/notes-directory/${qz.uuid}`">
							<a-row :gutter="24">
								<a-col :span="24" :md="24" :lg="24" :xl="24">
									<img src="../../../../public/images/folder.png" width="60px"/>
									<h6 style="font-weight: normal;">{{ qz.name }}</h6>
								</a-col>
							</a-row>
						</a>
						
						<a-row v-if="isCreator">
							<a-col :span="24" class="text-right mt-2">
								<a-button type="text" size="small" id="editBtn" class="text-silabu" silabu @click="onEditDirectory(qz)"><a-icon type="form" theme="outlined"/> Edit</a-button>
							
								<a-button type="text" size="small" id="deleteBtn" class="text-danger" @click="onDelete(qz.uuid)" danger><a-icon type="delete" theme="outlined" /> Delete</a-button>
							</a-col>
						</a-row>
						
					</a-card>
				</a-col>

			</a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="text-center" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<double-bounce class="" ></double-bounce>

				</a-col>

			</a-row>
		</div>

		<div v-if="!loadingData && message.length > 0">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 8, offset: 0}" class="text-center  justify-items-center">

					<label>{{message}}</label>

				</a-col>

			</a-row>
		</div>


		<!-- FORM MODAL -->
		<a-modal v-model="directory.showModal" :footer="null">
			<h4 class="text-center">{{  directory.isCreate ? 'Add Folder' : 'Edit Folder'}}</h4>
			<a-form-model
				:form="form"
				class="login-form"
				@submit="handleSubmit"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Name" :colon="false">
					<a-input 
						v-decorator="['name',
						{ rules: [{ required: true, message: 'Please input your name of the folder!' }] },
						]" placeholder="Name" 
						v-model="directory.name"/>
				</a-form-item>

				<a-form-item>
					<a-button type="silabu" :loading="directory.loading" id="btn" html-type="submit" class="login-form-button text-white">
						{{ directory.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingData: false,
                directories: [],
				form: this.$form.createForm(this, { name: 'notes_login' }),
				message: '',
				pagination: {
					search: '',
                    perPage: 100,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },
				directory: {
					loading: false,
					uuid: null,
					name: null,
					type: 'notes',
					isCreate: true,
					showModal: false,
				}
      		}
		},
		// beforeCreate() {
		// 	// Creates the form and adds to it component's "form" property.
		// 	this.form = this.$form.createForm(this, { name: 'notes_login' });
		// },
		created() {
			this.getDirectories();
		},

		methods: {

			onEditDirectory(item) {
				this.directory.uuid = item.uuid;
				this.directory.name = item.name;
				this.directory.isCreate = false;
				this.directory.showModal = true;
			},

			launchModal() {
				this.directory.uuid = null;
				this.directory.name = null;
				this.directory.isCreate = true;
				this.directory.showModal = true;
			},


			async getDirectories() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

				const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/classes/${this.$route.params.uuid}/notes/directories?page=${currentPage}&perPage=${perPage}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then((response) => {
                    if (response.status >= 200 || response.status <= 210) {
						
						if(response.data.data.length > 0) {

							this.directories = response.data.data;

						}else{
							this.message = 'No questions available'
						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			handleSubmit(e) {

				e.preventDefault();

				this.form.validateFields(async (err, values) => {
					
					if ( !err ) {

						if(this.directory.isCreate) {

							this.directory.loading = true;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/classes/${this.$route.params.uuid}/notes/directories`;

							const config = {
								headers: {
									'Content-Type': 'application/json',
									'Authorization': `Bearer ${token}`,
								},
							};

							this.directory.uuid = undefined;

							this.$AXIOS.post(url, this.directory, config).then((response) => {
								
								this.directory.uuid = null,
								this.directory.name = null,
								this.directory.isCreate = true,
								this.directory.showModal = false,
								this.directory.loading = false;

								this.form.resetFields()

								this.getDirectories()

								// this.loadingData = false;

							}).catch(async(error) => {
								
								this.directory.loading = false;

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else{
							
							this.directory.loading = true;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/classes/${this.$route.params.uuid}/notes/directories/${this.directory.uuid}`

							const config = {
								headers: {
									'Content-Type': 'application/json',
									'Authorization': `Bearer ${token}`,
								},
							};

							this.directory.uuid = undefined;

							this.$AXIOS.patch(url, this.directory, config).then((response) => {
								
								this.directory.uuid = null,
								this.directory.name = null,
								this.directory.isCreate = true,
								this.directory.showModal = false,
								this.directory.loading = false;

								this.form.resetFields()

								this.getDirectories()

							}).catch(async(error) => {

								this.directory.loading = false;
								
								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}
					}
				})
			},


			async onDelete(uuid) {

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/classes/${classUuid}/notes/directories/${uuid}`

				const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

				this.$AXIOS.delete(url, config).then(async(response) => {
					
					this.notify('Folder was deleted successfully', 'success')
					
					this.getDirectories()

				}).catch(async(error) => {
					
					// this.loadingData = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

			notify(message, type) {
				notification[type]({
					message: message,
					description: '',
					placement: 'topRight',
					duration: 3,
				});

			},

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>