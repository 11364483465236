<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10 pb-10">

		<a-row :gutter="24" class="mt-10">

			<a-col :span="24" :md="12" :lg="12" :xl="12" class="mb-24">
				<h4>List of Past Papers</h4>
			</a-col>

			<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-right">
				<a-button id="btn" type="silabu" @click="launchModal">
					<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Past Paper
				</a-button>
			</a-col>

		</a-row>
		
		<div v-if="!loadingData && message.length == 0">

			<a-row :gutter="24">

				<a-col class="mb-24" style="height: 100%;" :span="24" :md="6" :lg="6" :xl="6" v-for="nt in pastPapers" :key="nt.uuid">
					<a-card class=" px-5 h-100">

						<a-row :gutter="24">
							<a-col :span="24" :md="24" :lg="24" :xl="24">
								<img src="../../../../public/images/pdf.png" width="60px" v-if="nt.type == 'pdf'"/>
								<img src="../../../../public/images/file.png" width="60px" v-if="nt.type != 'pdf'"/>
								<h6 style="font-weight: normal; font-size: 15px;" class="pl-5 ml-5 mt-5">{{ nt.title }}</h6>
							</a-col>

							<a-col :span="24" :md="24" :lg="24" :xl="24">
								<small class="pl-5 ml-5 mt-3 mr-5 text-silabu"><a-icon type="eye" theme="outlined" style="font-size: 14px;"/> &nbsp;<span style="font-size: 14px;">{{ nt.viewersCount }}</span></small>
								&nbsp;
								<small class="pl-5 ml-5 mt-3 mr-5 text-silabu"><a-icon type="like" theme="outlined" style="font-size: 14px;"/> &nbsp;<span style="font-size: 14px;">{{ nt.appraisalsCount }}</span></small>
								&nbsp;
								<small class="pl-5 ml-5 mt-3 text-silabu"><a-icon type="clock-circle" theme="outlined" style="font-size: 12px;" /> &nbsp;<span style="font-size: 13px;">{{ moment(nt.createdAt).format("DD MMM YYYY") }}</span></small>
							</a-col>
						</a-row>
						
						<a-row>
							<a-col :span="24" class="text-left mt-5 pt-5">
								<a-button type="text" size="small" id="viewBtn" class="text-silabu" silabu @click="onViewFile(nt)"><a-icon type="eye" theme="outlined"/> View</a-button>

								<a-button type="text" size="small" id="editBtn" class="text-silabu" silabu @click="onEditDirectory(nt)"><a-icon type="form" theme="outlined"/> Edit</a-button>
							
								<a-button type="text" size="small" id="deleteBtn" class="text-danger" @click="onDelete(nt.uuid)" danger><a-icon type="delete" theme="outlined" /> Delete</a-button>
							</a-col>
						</a-row>
						
					</a-card>
				</a-col>

			</a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="24" justify="space-around" align="middle" class="text-center" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" class="text-center  justify-items-center">

					<double-bounce class="" ></double-bounce>

				</a-col>

			</a-row>
		</div>

		<div v-if="!loadingData && message.length > 0">

			<a-row type="flex" :gutter="24" justify="space-around" align="middle" class="" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" class="text-center  justify-items-center">

					<label>{{message}}</label>

				</a-col>

			</a-row>
		</div>


		<!-- FORM MODAL -->
		<a-modal v-model="pastPaper.showModal" :footer="null">
			<h4 class="text-center">{{ pastPaper.isCreate ? 'Add Past Paper' : 'Edit Past Paper'}}</h4>
			<a-form-model
				:form="form"
				class="login-form"
				@submit="handleSubmit"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Name *" :colon="false">
					<a-input 
						v-decorator="['name',
						{ rules: [{ required: true, message: 'Please input your name of the file!' }] },
						]" placeholder="Name" 
						v-model="pastPaper.title"/>
				</a-form-item>

				<a-form-item class="mb-10" label="File / Question File *" :colon="false" v-if="pastPaper.isCreate">
					<a-input 
						type="file"
						ref="file"
						v-decorator="['file',
							{ rules: [{ required: true, message: 'Please upload file!' }] },
						]" 
						placeholder="File" 
						@change="handleFileChange"/>
				</a-form-item>

				<a-form-item class="mb-10" label="Answer File (optional)" :colon="false" v-if="pastPaper.isCreate">
					<a-input 
						type="file"
						ref="answerFile"
						placeholder="Answer File" 
						@change="handleAnswerFileChange"/>
				</a-form-item>

				<a-form-item>
					<a-button type="silabu" :loading="pastPaper.loading" id="btn" html-type="submit" class="login-form-button text-white">
						{{ pastPaper.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../../components/Widgets/WidgetCounter' ;

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingData: false,
				submitLoading: false,
                pastPapers: [],
				form: this.$form.createForm(this, { name: 'past_papers_login' }),
				message: '',
				pagination: {
					search: '',
                    perPage: 100,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },
				pastPaper: {
					uuid: null,
					title: null,
					thumbnailUrl: null,
					file: null,
					answerFile: null,
					examAnswerThumbnailUrl: null,
					loading: false,
					isCreate: true,
					showModal: false,
				}
      		}
		},
		
		created() {
			this.getPastPapers();
		},

		methods: {

			async onViewFile(item) {
				
				await localStorage.setItem("pdfFilePath", item.url)
				await localStorage.setItem("fileName", item.thumbnailUrl)

				window.open(`/#/my-classes/view/${this.$route.params.uuid}/pdf-viewer`, '_blank');

				// this.$router.push(`/my-classes/view/${this.$route.params.uuid}/pdf-viewer`);
			},

			onEditDirectory(item) {
				this.pastPaper.uuid = item.uuid;
				this.pastPaper.title = item.title;
				this.pastPaper.isCreate = false;
				this.pastPaper.showModal = true;
			},

			launchModal() {
				this.pastPaper.uuid = null;
				this.pastPaper.title = null;
				this.pastPaper.thumbnailUrl = null;
				this.pastPaper.file = null;
				this.pastPaper.answerFile = null;
				this.pastPaper.examAnswerThumbnailUrl = null;
				this.pastPaper.loading = false;
				this.pastPaper.isCreate = true;
				this.pastPaper.showModal = true;
			},


			handleFileChange(event) {
				this.pastPaper.file = event.target.files[0];
				this.pastPaper.thumbnailUrl = event.target.files[0].name;
			},


			handleAnswerFileChange(event) {
				this.pastPaper.answerFile = event.target.files[0];
				this.pastPaper.examAnswerThumbnailUrl = event.target.files[0].name;
			},


			async getPastPapers() {

				this.loadingData = true;
				this.message = '';

				let token = await localStorage.getItem("user_token")

				const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/classes/${this.$route.params.uuid}/notes/directory/${this.$route.params.directoryUuid}?page=${currentPage}&perPage=${perPage}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then((response) => {
                    if (response.status >= 200 || response.status <= 210) {

						if(response.data.data.length > 0) {

							this.pastPapers = response.data.data;

						}else{
							this.message = 'No Past Papers available'
						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			handleSubmit(e) {

				e.preventDefault();

				this.form.validateFields(async (err, values) => {
					
					if ( !err ) {

						if(this.pastPaper.isCreate) {

							this.pastPaper.loading = true;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/classes/${this.$route.params.uuid}/notes`;

							const config = {
								headers: {
									'Content-Type': 'multipart/form-data',
									'Authorization': `Bearer ${token}`,
								},
							};

							const formData = new FormData(); 

							formData.append("title", this.pastPaper.title); 
							formData.append("directory", this.$route.params.directoryUuid); 
							formData.append("file", this.pastPaper.file); 
							formData.append("thumbnailUrl", this.pastPaper.thumbnailUrl); 
							formData.append("answerFile", this.pastPaper.answerFile); 
							formData.append("examAnswerThumbnailUrl", this.pastPaper.examAnswerThumbnailUrl); 

							this.$AXIOS.post(url, formData, config).then((response) => {
								
								this.pastPaper.uuid = null,
								this.pastPaper.title = null,
								this.pastPaper.showModal = false,
								this.pastPaper.thumbnailUrl = null,
								this.pastPaper.file = null,
								this.pastPaper.answerFile = null,
								this.pastPaper.examAnswerThumbnailUrl = null,
								this.pastPaper.isCreate = true,
								this.pastPaper.loading = false;

								this.form.resetFields()

								this.notify('File was successfully uploaded', 'success')

								this.getPastPapers()

							}).catch(async(error) => {
								
								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								this.pastPaper.loading = false;

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}else {

							this.pastPaper.loading = true;

							let token = await localStorage.getItem("user_token")

							let url = `${this.$BACKEND_URL}/classes/${this.$route.params.uuid}/notes/${this.pastPaper.uuid}`;

							const config = {
								headers: {
									'Content-Type': 'application/json',
									'Authorization': `Bearer ${token}`,
								},
							};

							this.$AXIOS.patch(url, { title: this.pastPaper.title }, config).then((response) => {
								
								this.pastPaper.uuid = null,
								this.pastPaper.title = null,
								this.pastPaper.showModal = false,
								this.pastPaper.thumbnailUrl = null,
								this.pastPaper.file = null,
								this.pastPaper.answerFile = null,
								this.pastPaper.examAnswerThumbnailUrl = null,
								this.pastPaper.isCreate = true,
								this.pastPaper.loading = false;

								this.form.resetFields()


								this.getPastPapers()

							}).catch(async(error) => {

								this.notify(error.response.data.message ?? 'Connection errror', 'error')

								this.pastPaper.loading = false;
								
								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)

									this.$router.push(`/sign-in`);
								}
							});

						}

					}
				})
			},


			async onDelete(uuid) {

				let token = await localStorage.getItem("user_token")

				let url = `${this.$BACKEND_URL}/classes/${this.$route.params.uuid}/notes/${uuid}`

				const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

				this.$AXIOS.delete(url, config).then(async(response) => {
					
					this.notify('File was deleted successfully', 'success')
					
					this.getPastPapers()

				}).catch(async(error) => {
					
					// this.loadingData = false;

					this.notify(error.response.data.message ?? 'Connection errror', 'error')

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

						this.$router.push(`/sign-in`);
					}
				});

			},

			notify(message, type) {
				notification[type]({
					message: message,
					description: '',
					placement: 'topRight',
					duration: 3,
				});

			},

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover, #viewBtn, #viewBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>