<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		

		<!-- <div v-if="!loadingData"> -->

			<a-row :gutter="24">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24">
					<a-card style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">

						<a-row :gutter="24">

							<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mt-12">
								<h5>List of Class Users</h5>
							</a-col>

							<a-col :span="24"  :md="12" :lg="12" :xl="12" class="text-right">
								<a-button type="danger" @click="selectClass" style="margin-right: 30px;">
									<a-icon type="delete" theme="outlined"/> Delete All Class Users
								</a-button>
								<a-button id="btn" type="silabu" @click="toAddUserIdentification">
									<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add User Identifications
								</a-button>
									
							</a-col>
						</a-row>

						<a-row :gutter="24" class="mt-20 pt-20">
							<a-col :span="24"  :md="24" :lg="24" :xl="24" class="text-right">
								<div class="pull-right">
									<a-input-search  
										placeholder="search external id, extra-external id..." 
										style="max-width: 300px;" 
										v-model="pagination.search"  
										@search="onSearchItem">
										<template #suffix v-if="pagination.search != null && pagination.search.length > 0">
											<a-icon type="close" theme="outlined" class="text-danger mr-15"  @click="onClearSearchItem" />
										</template>
									</a-input-search>
											<!-- <fg-input class="input-sm"
														placeholder="Search"
														v-model="searchQuery"
														addon-right-icon="nc-icon nc-zoom-split">
											</fg-input> -->
								</div>
							</a-col>
						</a-row>
						
						<a-row :gutter="24">
							<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-12" style="margin-top: 30px;">
								<a-table 
									:columns="tableColumns" 
									:data-source="institutionUsers" 
									:loading="loadingData" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">

									<template slot="s_no" slot-scope="s_no, record, index">
										{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
									</template>

									<template slot="fullName" slot-scope="row">
										<h6 class="m-0">
											{{ row.user != null ? `${row.user.firstName } ${row.user.lastName}` : '-' }} 
										</h6>
									</template>

									<template slot="role" slot-scope="row">
										<h6 class="m-0">
											<a-badge :count="row.role != null ? `${row.role.name}` : '-'" :number-style="{ backgroundColor: '#744FBF' }" />
										</h6>
									</template>

									<template slot="contacts" slot-scope="row">
										<p class="m-0">
											<strong>Phone:</strong> {{ row.user != null && row.user.mobile != null ? `${row.user.mobile }` : 'N/A' }}  <br/>
											<strong>Email:</strong> {{ row.user != null && row.user.email != null ? `${row.user.email }` : 'N/A' }}
										</p>
									</template>

									<template slot="clazz" slot-scope="row">
										{{ row.clazz.name }}
									</template>

									<template slot="externalId" slot-scope="row">
										{{ row.externalId }}
									</template>

									<template slot="extraExternalId" slot-scope="row">
										{{ row.extraExternalId }}
									</template>

									<template slot="isActive" slot-scope="row">
										<h6 class="m-0">
											<a-badge :count="row.isActive ? 'Active' : 'Inactive'" :number-style="{ backgroundColor: row.status ? '#188C54' : '#FF0100' }" />
										</h6>
									</template>

									<template slot="createdAt" slot-scope="row">
										<p class="m-0">
											{{ moment(row.createdAt).format('DD MMM YYYY')}}
										</p>
									</template>

									<template slot="action" slot-scope="row">
										<a-button id="btnDelete" type="text" style="color: #F44335;" @click="showClassUserDeletionConfirmation(row)">
											<a-icon type="delete" theme="outlined"/> Delete
										</a-button>
									</template>
                            

								</a-table>
							</a-col>
						</a-row>

						<a-row :gutter="24">
							<a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-24 mb-24 text-right" style="margin-top: 20px;">
								<a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
							</a-col>
						</a-row>
						
					</a-card>
				</a-col>

			</a-row>
			
			

			<!--CLASS USER DELETE CONFIRMATION -->
			<a-modal v-model="deleteDataItem.showModal" :footer="null" width="416px">

				<a-row :gutters="24" class="">
					<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
						<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
					</a-col>

					<a-col :span="24" :md="21" :lg="21" :xl="21">

						<a-row :gutters="24">
							<a-col :span="24">
								<h6>Do you want to delete this record ({{ deleteDataItem.externalId }})?</h6>
								<p>Once you delete, you will not be able to recover this inforamtion.</p>
							</a-col>
						</a-row>

						<a-row :gutters="24">
							<a-col :span="24" class="text-right">
								<a-button id="txtBtnCancel" type="text" class="text-secondary" @click="cancelClassUserDeletion">
									Cancel
								</a-button>
								&nbsp;&nbsp;
								<a-button id="txtBtnDelete"  :loading="deleteDataItem.loading" type="text" class="text-danger" @click="onDeleteClassUser">
									Delete
								</a-button>
							</a-col>
						</a-row>
					</a-col>
				</a-row>
		
			</a-modal>


			<!-- FORM MODAL -->
			<a-modal v-model="deleteFromClassModal" :footer="null">
				<h4 class="text-center">Select Class</h4>
				<a-form-model
					class="login-form mb-0 pb-0"
					layout="vertical"
					@submit.prevent="showClassUsersDeletionConfirmation">

					<a-form-item 
						class="mt-20 mb-10 pb-0" 
						label="Class"
						placeholder="Select Class"
						:colon="false">
						<a-select v-model="deleteDataClass.uuid" placeholder="Select Class">
							<a-select-option v-for="option in classes"
								:value="option.uuid"
								:key="option.uuid">
								{{ option.name }}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="mt-20"> 
						<a-button type="silabu" id="btn" html-type="submit" class="text-white">
							Delete
						</a-button>
					</a-form-item>

				</a-form-model>
				
			</a-modal>


			<!--ALL CLASS USER DELETE CONFIRMATION -->
			<a-modal v-model="deleteDataClass.showModal" :footer="null" width="416px">

				<a-row :gutters="24" class="">
					<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
						<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
					</a-col>

					<a-col :span="24" :md="21" :lg="21" :xl="21">

						<a-row :gutters="24">
							<a-col :span="24">
								<h6>Do you want to delete all class users in <span class="text-danger">{{ deleteDataClass.class }}</span> Class?</h6>
								<p>Once you delete, you will not be able to recover this inforamtion.</p>
							</a-col>
						</a-row>

						<a-row :gutters="24">
							<a-col :span="24" class="text-right">
								<a-button id="txtBtnCancel" type="text" class="text-secondary" @click="cancelClassUsersDeletion">
									Cancel
								</a-button>
								&nbsp;&nbsp;
								<a-button id="txtBtnDelete" :loading="deleteDataClass.loading" type="text" class="text-danger" @click="onDeleteClassUsers">
									Delete
								</a-button>
							</a-col>
						</a-row>
					</a-col>
				</a-row>
		
			</a-modal>

		<!-- </div> -->

		<!-- <div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin size="large" />

				</a-col>

			</a-row>
		</div> -->
	</div>
</template>

<script>
	import Papa from 'papaparse';
	import {DoubleBounce} from 'vue-loading-spinner'
	import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	const tableColumns = [
		{
			title: 'S/N',
			scopedSlots: { customRender: 's_no' },
			width: 100,
		},
		{
			title: 'Full Name',
			scopedSlots: { customRender: 'fullName' },
			class: 'font-bold',
		},
		{
			title: 'Role',
			scopedSlots: { customRender: 'role' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Contacts',
			scopedSlots: { customRender: 'contacts' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Class',
			scopedSlots: { customRender: 'clazz' },
			class: 'text-sm',
		},
		{
			title: 'External ID',
			scopedSlots: { customRender: 'externalId' },
			class: 'font-bold text-sm',
		},
		{
			title: 'Extra-External ID',
			scopedSlots: { customRender: 'extraExternalId' },
			class: 'text-sm',
		},
		{
			title: 'Status',
			scopedSlots: { customRender: 'isActive' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Created At',
			scopedSlots: { customRender: 'createdAt' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Action',
			scopedSlots: { customRender: 'action' },
			class: 'text-muted text-sm',
		},
	];


	export default ({

		
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				institutionUsers: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },
				
				tableColumns,
				file: '',
				content: [],
				parsed: false,

				classes: [],

				deleteDataItem: {
					uuid: null,
					externalId: null,
					showModal: false,
					loading: false,
				},

				deleteFromClassModal: false,


				deleteDataClass: {
					uuid: null,
					class: null,
					showModal: false,
					loading: false,
				}
				
				
      		}
		},
		watch: {
			currentPage: {
                handler() {
					// console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getInstituteClassUsers()
                },
            },
            deep: true,
        },
		created() {
			this.getInstituteClassUsers()
			this.getInstituteClasses()
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {

					this.pagination.currentPage = 1;

					this.getInstituteClassUsers()
				}else{
					let message = "Please enter something to search"
					let type = "danger"

					this.notify(type, message)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.pagination.currentPage = 1;

				this.getInstituteClassUsers();
			},

			async toAddUserIdentification(item) {
				
				this.$router.push(`/users/add-user-identifications`);
			},

			handleFileUpload(event){
				this.file = event.target.files[0];
				this.parseFile();
			},

			parseFile(){
				Papa.parse( this.file, {
					header: true,
					skipEmptyLines: true,
					complete: function( results ){
						this.content = results;
						this.parsed = true;
					}.bind(this)
				} );
			},

			async getInstituteClassUsers() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")
				let user_details = JSON.parse(await localStorage.getItem("user_details"))
				let institution_details = JSON.parse(await localStorage.getItem("institution_details"))

                const { search, currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/educational-institutions/class-users/${institution_details.uuid}?search=${search}&page=${currentPage}&perPage=${perPage}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						// console.log(response.data)
						this.institutionUsers = response.data.data;
						this.pagination.totalPages = response.data.totalPages;
						this.pagination.total = response.data.total;
						this.pagination.currentPage = response.data.currentPage;
						this.currentPage = response.data.currentPage;
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

			async getInstituteClasses() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")
				let institution_details = JSON.parse(await localStorage.getItem("institution_details"))

                const currentPage = 1;
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/educational-institutions/classes/${institution_details.uuid}/?page=${currentPage}&perPage=${perPage}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.classes = response.data.data
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

			showClassUserDeletionConfirmation(classUser) {
				this.deleteDataItem.uuid = classUser.uuid;
				this.deleteDataItem.externalId = classUser.externalId;
				this.deleteDataItem.showModal = true;
			},

			cancelClassUserDeletion() {
				this.deleteDataItem.uuid = null;
				this.deleteDataItem.externalId = null;
				this.deleteDataItem.showModal = false;
			},

			onDeleteClassUser() {

				this.deleteDataItem.loading = true;

                let url = `${this.$BACKEND_URL}/educational-institutions/class-users/${this.deleteDataItem.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataItem.uuid = null;
					this.deleteDataItem.externalId = false;
					this.deleteDataItem.showModal = false;
                
                    this.deleteDataItem.loading = false;

					let message = response.data.message;
                    let type = "success"

                    this.notify(message, type)

					this.getInstituteClassUsers();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "error"

                    this.notify(message, type)

                    this.deleteDataItem.loading = false;
                });
			},


			selectClass() {
				this.deleteFromClassModal = true;
			},


			async showClassUsersDeletionConfirmation() {
				if(this.deleteDataClass.uuid != null) {

					let clazz = await this.classes.filter(claz => claz.uuid == this.deleteDataClass.uuid)

					if(clazz.length > 0) {
						this.deleteDataClass.class = clazz[0].name;
						this.deleteFromClassModal = false;
						this.deleteDataClass.showModal = true;
					}else{
						this.deleteDataClass.uuid = false;
						this.deleteFromClassModal = false;

						let message = "Invalid class, try again!!!";
						let type = "error"

						this.notify(message, type)
					}
				}else{
					let message = "Select class before you submit!";
                    let type = "error"

                    this.notify(message, type)
				}
			},

			cancelClassUsersDeletion() {
				this.deleteDataClass.uuid = null;
				this.deleteDataClass.class = null;
				this.deleteDataClass.showModal = false;
				this.deleteDataClass.loading = false;
			},

			onDeleteClassUsers() {

				this.deleteDataClass.loading = true;

                let url = `${this.$BACKEND_URL}/educational-institutions/class-users/all/${this.deleteDataClass.uuid}`;

                this.$AXIOS.delete(url).then((response) => {
                        
					this.deleteDataClass.uuid = null;
					this.deleteDataClass.class = null;
                    this.deleteDataClass.loading = false;
					this.deleteDataClass.showModal = false;
                
                    this.deleteDataClass.loading = false;

					let message = response.data.message;
                    let type = "success"

                    this.notify(message, type)

					this.getInstituteClassUsers();

                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "error"

                    this.notify(message, type)

                    this.deleteDataClass.loading = false;
                    this.deleteDataClass.showModal = false;
                });
			},

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 5,
                });
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a{
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:color{
    color: #734EBF !important;
}

#classImg {
	border-radius: 10px;
}

a:hover {
    color: inherit !important;
}
#txtBtn, #txtBtn:hover {
	border: none;
}

#btnDelete, #btnDelete:hover {
	border: none;
}

#txtBtnCancel, #txtBtnCancel:hover {
	color: #B37FEA;
	border: none;
}

#txtBtnDelete, #txtBtnDelete:hover {
	color: #F5212D;
	border: none;
}
#submitBtn, #submitBtn:hover {

}
</style>