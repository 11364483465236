<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		

		<div v-if="!loadingData">

			

			<a-row :gutter="24">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24">
					<a-card style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">

						<a-row :gutter="24">

							<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mt-12">
								<h5>List of Classes</h5>
							</a-col>

							
						</a-row>
						
						<a-row :gutter="24">
							<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-12" style="margin-top: 30px;">
								<a-table 
									:columns="tableColumns" 
									:data-source="classes" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;">

									<template slot="s_no" slot-scope="s_no, record, index">
										{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
									</template>

									<template slot="icon" slot-scope="row">
											<a-avatar 
												v-if="row.iconUrl != null && row.iconUrl.length > 0"
												:size="40" 
												:src="row.iconUrl != null ? 
													row.iconUrl : 
													'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" 
												alt=""/>

											<a-avatar :size="40" v-if="row.iconUrl == null || row.iconUrl.length == 0" style="color: #744FBF">{{ row.name[0].toUpperCase() }}</a-avatar>

									</template>

									<template slot="name" slot-scope="row">
										<h6 class="m-0">
											{{ row.name }} 
										</h6>
									</template>

									<template slot="slots" slot-scope="row">
										<p class="m-0">
											{{ row.slots != null ? row.slots : '-' }} 
										</p>
									</template>

									<template slot="payment" slot-scope="row">
										<h6 class="m-0">
											<a-badge :count="row.isPaid ? `${row.joiningFee}` : 'Free'" :number-style="{ backgroundColor: '#744FBF' }" />
										</h6>
									</template>

									<template slot="creator" slot-scope="row">
										<h6 class="m-0">
											{{ row.creator != null ? `${row.creator.firstName} ${row.creator.lastName}` : '-' }}
										</h6>
									</template>

									<template slot="createdAt" slot-scope="row">
										<p class="m-0">
											{{ moment(row.createdAt).format('DD MMM YYYY')}}
										</p>
									</template>

									<template slot="action" slot-scope="row">
										<a-button type="text" size="small" id="viewBtn" class="text-silabu" silabu @click="onViewClass(row.uuid)"><a-icon type="eye" theme="outlined"/> View</a-button>
									</template>

								</a-table>
							</a-col>
						</a-row>

						<a-row :gutter="24">
							<a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-24 mb-24 text-right" style="margin-top: 20px;">
								<a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.totalPages" :pageSize="pagination.perPage" show-less-items />
							</a-col>
						</a-row>
						
					</a-card>
				</a-col>

			</a-row>
			
			

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin size="large" />

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import Papa from 'papaparse';
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

	const tableColumns = [
		{
			title: 'S/N',
			scopedSlots: { customRender: 's_no' },
			width: 100,
		},
		{
			title: 'Icon',
			scopedSlots: { customRender: 'icon' },
			class: '',
		},
		{
			title: 'Name',
			scopedSlots: { customRender: 'name' },
			class: 'font-bold',
			width: 250,
		},
		{
			title: 'Slots',
			scopedSlots: { customRender: 'slots' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Joining Fee',
			scopedSlots: { customRender: 'payment' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Creator',
			scopedSlots: { customRender: 'creator' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Created At',
			scopedSlots: { customRender: 'createdAt' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Action',
			align: 'center',
			scopedSlots: { customRender: 'action' },
			class: 'text-center',
		},
	];

	export default ({

		
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				classes: [],
				currentPage: 1,
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				tableColumns,
				file: '',
				content: [],
				parsed: false,
				
				
      		}
		},
		watch: {
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getInstituteClasses()
                },
            },
            deep: true,
        },
		created() {
			this.getInstituteClasses()
		},
		methods: {

			async onViewClass(uuid) {
				
				this.$router.push(`/classes/view/${uuid}`);
			},

			handleFileUpload(event){
				this.file = event.target.files[0];
				this.parseFile();
			},

			parseFile(){
				Papa.parse( this.file, {
					header: true,
					skipEmptyLines: true,
					complete: function( results ){
						this.content = results;
						this.parsed = true;
					}.bind(this)
				} );
			},

			async getInstituteClasses() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")
				let user_details = JSON.parse(await localStorage.getItem("user_details"))
				let institution_details = JSON.parse(await localStorage.getItem("institution_details"))

                const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/educational-institutions/classes/${institution_details.uuid}/?page=${currentPage}&perPage=${perPage}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.classes = response.data.data;
						this.pagination.totalPages = response.data.totalPages;
						this.pagination.total = response.data.total;
						this.pagination.currentPage = response.data.currentPage;
						this.currentPage = response.data.currentPage;
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a{
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:color{
    color: #734EBF !important;
}

#classImg {
	border-radius: 10px;
}

a:hover {
    color: inherit !important;
}

#viewBtn, #viewBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}
</style>